import React, { useState } from 'react'
import { navigate, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { TagCloud } from 'react-tagcloud'
import slugify from 'slugify'

/* COMPONENTS */
import Menu from '../../components/receitas/receitas-header'
import Layout from '../../components/Layout/index'
import SideBar from '../../components/receitas/SideBarReceitas/sideBarReceitas'
import SectionLancamentos from '../../components/SectionLancamentos/sectionLancamentos'
import InputSearch from '../../components/receitas/SideBarReceitas/inputSearch'
import Pagination from '../../components/busca/pagination/pagination'

/*  STYLES */
import '../styles/receitas.scss'

const TagsHome = ({ data }) => {
  const breadC = {
    visible: true,
    color: 'dark',
    items: [
      { path: '/', name: 'Home' },
      { path: '/receitas/', name: 'Receitas' },
      { path: '/receitas/tags/', name: 'Tags' }
    ]
  }
  const _arrayOfAllTags = []
  const [currentPage, setCurrentPage] = useState(0)
  const [postPerPage] = useState(55)

  data.allContentfulReceitas.edges.map(tags => {
    if (tags.node.tagsReceitas !== null) {
      tags.node.tagsReceitas.map(receitaTag => {
        if (!_arrayOfAllTags.map(arrayTag => arrayTag.value).includes(receitaTag.trim())) {
          _arrayOfAllTags.push({ value: receitaTag.trim(), count: 0 })
        }
        _arrayOfAllTags.map(arrayTag => {
          if (arrayTag.value === receitaTag.trim()) {
            arrayTag.count += 1
          }
        })
      })
    }
  })

  _arrayOfAllTags.sort(function (a, b) { return b.count - a.count })

  const customRenderer = (tag, size, color) => {
    console.log(size)
    return (
      <span
        key={tag.value}
        style={{
          fontSize: `${tag.count === 1 ? 3 / 2 : size / 2}em`
        }}
        className='font-Lato cursor-pointer text-capitalize d-inline-block pl-0 ml-0 px-1 mx-1'
      >
        {tag.value}<b className='font-size-12'> ({tag.count})</b>
      </span>
    )
  }

  const RedirectLink = (tag) => {
    navigate(`/receitas/tags/${slugify(tag.toLowerCase())}/`)
  }

  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <title>TAGS DIA - Receitas saborosas, rápidas e fáceis para você!</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="No DIA Supermercados, além dessa receita, você também encontra receitas de Sobremesas, Pães e Salgados, dentre outras. Confira!" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <section id="receitas">
        <section id="filtragem-home">
          <div className="menu-receitas">
            <Menu />
          </div>
        </section>
        <section className='container'>
          <div className="row">
            <div className="col-12 col-lg-8 mb-4">
              <div className='d-block d-sm-none' >
                <InputSearch />
              </div>
              <div className="d-flex row">
                <div className="col-12">
                  <h2 className="text-red mb-3 font-size-40px">TAGS</h2>
                </div>
                <div className='col-12 mb-4'>
                  <TagCloud
                    minSize={4}
                    maxSize={7}
                    tags={_arrayOfAllTags.slice((postPerPage * currentPage), (postPerPage * (currentPage + 1)))}
                    onClick={tag => RedirectLink(tag.value)}
                    renderer={customRenderer}
                    className='text-left font-weight-bold'
                  />
                </div>
                <div className='d-flex justify-content-center w-100 mb-4'>
                  <Pagination
                    postPerPage={postPerPage}
                    totalPosts={_arrayOfAllTags}
                    handlers={{
                      currentPage,
                      setCurrentPage
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-4'>
              <SideBar />
            </div>
          </div>
        </section>
        <section className='container-fluid bg-dia-light-gray'>
          <section className="container py-5 p-0">
            <SectionLancamentos />
          </section>
        </section>
      </section>
    </Layout >
  )
}

export default TagsHome

export const query = graphql`
query {
  allContentfulReceitas {
    edges {
      node {
        tagsReceitas
      }
    }
  }
}
`
