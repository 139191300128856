import React from 'react'
import ReactPaginate from 'react-paginate'

/* STYLES */
import '../../../pages/styles/receitas.scss'

const Pagination = ({ postPerPage, totalPosts, handlers }) => {
  const pageNumbers = [...new Array(Math.ceil(totalPosts.length / postPerPage))]

  const changePage = (page) => {
    handlers.setCurrentPage(page.selected)
  }

  return (
    <div>
      <ReactPaginate
        previousLabel={''}
        nextLabel={''}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageNumbers.length < 1 ? 1 : pageNumbers.length}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={changePage}
        containerClassName={'d-flex align-items-center text-dark pagination'}
        activeLinkClassName={'active'}
        nextClassName={`nextClassName ${pageNumbers.length > totalPosts.length ? 'disabled cursor-none' : ''}`}
        previousClassName={`previousClassName ${pageNumbers.length < 1 ? 'disabled' : ''}`}
      />
    </div>
  )
}
export default Pagination
